export default [
  {
    name: '数据看板',
    icon: 'nc-icon nc-bank',
    path: '/admin/overview'
  },
  {
    name: '详细打点数据',
    icon: 'nc-icon nc-atom',
    path: '/admin/dotdata'
  }
]
