<template>
  <div class="row" style="margin-top: 3%;">
    <div v-if="searchResult_detail.length==0" class="col-lg-4 offset-lg-4">
      <fg-input>
        <h6 class="card-title">目标平台</h6>
        <el-select class="select-info"
                    size="large"
                    placeholder="请选择"
                    v-model="channelName">
          <el-option v-for="option in channelList"
                      class="select-info"
                      :value="option.id"
                      :label="`${option.name}`"
                      :key="option.id">
          </el-option>
        </el-select>
      </fg-input>
      <fg-input>
        <h6 class="card-title">目标项目</h6>
        <el-select class="select-info"
                    size="large"
                    placeholder="请选择"
                    v-model="gameName">
          <el-option v-for="option in gameList"
                      class="select-info"
                      :value="option.tag"
                      :label="`${option.name}`"
                      :key="option.id">
          </el-option>
        </el-select>
      </fg-input>
      <fg-input>
        <h6 class="card-title">目标版本</h6>
        <el-select class="select-info"
                    size="large"
                    placeholder="请选择"
                    v-model="gameVersion">
          <el-option v-for="option in versionList"
                      class="select-info"
                      :value="option.version"
                      :label="`${option.desc}`"
                      :key="option.id">
          </el-option>
        </el-select>
      </fg-input>
      <div class="row">
        <div class="col-md-6">
          <h6 class="card-title">起始时间(可选)</h6>
          <div class="form-group">
            <el-date-picker
              v-model="dateTimePicker1"
              type="datetime"
              placeholder=""
              :picker-options="pickerOptions1">
            </el-date-picker>
          </div>
        </div>
        <div class="col-md-6">
          <h6 class="card-title">终止时间(可选)</h6>
          <div class="form-group">
            <el-date-picker
              v-model="dateTimePicker2"
              type="datetime"
              placeholder=""
              :picker-options="pickerOptions1">
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <p-button type="primary" style="width: 100%;" @click="clickConfirm">查询(数据多会比较耗时，请不要重复点)</p-button>
        </div>
      </div>
    </div>
    <div v-if="isSearching" class="typography-line">
      <p class="text-danger" style="text-align: center;">查询中，请等待</p>
    </div>
    <div v-if="searchResult.length>0&&searchResult_detail.length==0" class="col-lg-8 offset-lg-2" style="margin-top: 3%;">
      <div class="card card-plain">
        <div class="card-header">
          <h4 class="title">查询结果</h4>
          <p class="category">总计{{searchResult.length}}条</p>
        </div>
        <div class="card-body table-responsive table-full-width" style="height: 600px;">
          <el-table :data="searchResult">
            <el-table-column label="名称" property="name"></el-table-column>
            <el-table-column label="总统计数" property="count"></el-table-column>
            <el-table-column label="用户去重数" property="dCount"></el-table-column>
            <el-table-column
              :min-width="60"
              fixed="right"
              class-name="td-actions"
              label="操作">
              <template slot-scope="props">
                <p-button type="default" link @click="handleDesc(props.$index, props.row)">
                  查看详细
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div v-if="searchResult_detail.length>0" class="col-lg-10 offset-lg-1">
      <div style="text-align: center;">
        <p-button type="info" @click="handleBack" style="width: 360px;text-align:center">
          <i slot="label" class="nc-icon el-icon-circle-close"></i>
          返回
        </p-button>
      </div>
      <div class="row" >
        <div v-for="oneData in activityChart"  class="offset-lg-1 col-lg-10">
          <chart-card :chart-data="oneData"
                      :chart-height="175"
                      chart-id="activity-bar-chart"
                      chart-type="Bar">
            <template slot="header">
              <h4 class="card-title">{{oneData.name}}</h4>
            </template>
          </chart-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option} from 'element-ui'
  import {Table, TableColumn} from 'element-ui'
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard';
  Vue.use(Table)
  Vue.use(TableColumn)

  export default {
    data(){
      return{
        isSearching:false,
        channelName:"",
        channelList:[
          {id:"hw",name:"海外APP"}
        ],
        gameName:"",
        gameList:[
        ],
        gameVersion:"",
        versionList:[

        ],
        searchResult:[],
        searchResult_detail:[],
        pickerOptions1: {
          shortcuts: [{
            text: '现在',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '一小时前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 1)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一天前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '三天前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 3)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一月前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', date)
            }
          }]
        },
        dateTimePicker1: null,
        dateTimePicker2: null,
        lastFindAllData:null,
        activityChart: []
      }
    },
    components: {
      [DatePicker.name]: DatePicker,
      [TimeSelect.name]: TimeSelect,
      [Slider.name]: Slider,
      [Tag.name]: Tag,
      [Input.name]: Input,
      [Button.name]: Button,
      [Option.name]: Option,
      [Select.name]: Select,
      ChartCard,
    },
    mounted() {
      this.gameList = [{tag:"LYDY",name:"钓鱼达人",id:1}];
    },
    watch:{
      gameName:{
        handler(){
          this.versionList = [{version:"v1.0.0",desc:"线上版",id:1}];
        }
      }
    },
    methods:{
      handleBack(){
        this.searchResult_detail = [];
      },
      handleDesc (index, row) {
        if(this.isSearching){
          return;
        }
        this.isSearching = true;
        //row.name
        let data = this.lastFindAllData;
        data.pName = row.name;
        this.axios.post(this.$myStore.ServerUrl+'/statistics/search',data).then((response)=>{
          this.isSearching = false;
          if(response.data.code == 200){
            this.searchResult_detail = [].concat(response.data.data);
            let listData = response.data.data;
            this.activityChart = [];
            //处理数据
            for(let i =0;i<listData.length;i++){
              let tempData = listData[i];
              if(tempData.info.length > 30){
                //太多了
                if(typeof tempData.info[0]._id == "number"){
                  //可以缩写，则缩写
                  tempData.info.sort((a,b)=>a._id-b._id);
                  let newInfo = [].concat(tempData.info.splice(0,10));
                  let miniData = tempData.info[0]._id;
                  let maxData = tempData.info[tempData.info.length-1]._id;
                  for(let j=1;j<=5;j++){
                    let newInfoTemp = {};
                    let tempMin = Math.round(miniData + (j-1)/14 * (maxData - miniData));
                    let tempMax = Math.round(tempMin + (maxData - miniData)/14);
                    let countNum = 0;
                    let dCount = 0;
                    for(let z=1;z<tempData.info.length;z++){
                      if(tempData.info[z]["_id"] >= tempMin && tempData.info[z]["_id"] < tempMax){
                        countNum+=tempData.info[z].total_count;
                        if(tempData.info[z].dCount){
                          dCount+=tempData.info[z].dCount;
                        }
                      }
                    }
                    if(tempMax > 10000){
                      newInfoTemp["_id"] = (tempMin / 10000).toFixed(1)+"万~"+(tempMax / 10000).toFixed(1)+"万";
                    }else if(tempMax > 1000){
                      newInfoTemp["_id"] = (tempMin / 1000).toFixed(1)+"k~"+(tempMax / 1000).toFixed(1)+"k";
                    }else{
                      newInfoTemp["_id"] = tempMin+"~"+tempMax;
                    }
                    newInfoTemp["total_count"] = countNum;
                    if(dCount > 0){
                      newInfoTemp["dCount"] = dCount;
                    }
                    newInfo.push(newInfoTemp);
                  }
                  tempData.info = newInfo;
                }else{
                  console.log("跳过了一个数据："+tempData.name);
                  continue;
                }
              }
              if(typeof tempData.info[0]._id == "number"){
                tempData.info.sort((a,b)=>a._id-b._id);
              }else{
                tempData.info.sort((a,b)=>b.total_count-a.total_count);
              }
              let tempDisplay = {};
              tempDisplay["name"] = tempData.name;
              tempDisplay["labels"] = [];
              tempDisplay["datasets"] = [
                {
                    label: "总数量",
                    borderColor: '#fcc468',
                    fill: true,
                    backgroundColor: '#fcc468',
                    hoverBorderColor: '#fcc468',
                    borderWidth: 6,
                    data: []
                }
              ];
              if(tempData.info[0].dCount != null){
                tempDisplay["datasets"].push({
                    label: "去重数量",
                    borderColor: '#aaff00',
                    fill: true,
                    backgroundColor: '#aaff00',
                    hoverBorderColor: '#aaff00',
                    borderWidth: 6,
                    data: []
                });
              }
              for(let j=0;j<tempData.info.length;j++){
                tempDisplay.labels.push(tempData.info[j]._id);
                tempDisplay.datasets[0].data.push(tempData.info[j].total_count);
                if(tempData.info[j].dCount){
                  tempDisplay.datasets[1].data.push(tempData.info[j].dCount);
                }
              }
              this.activityChart.push(tempDisplay);
            }
          }else{
            this.$notify(
              {
                message:response.data.msg,
                icon: 'nc-icon nc-app',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'danger'
              })
          }
        }).catch((response)=>{
          console.log(response);
        })
      },
      clickConfirm(){
        this.searchResult = [];
        if(this.channelName.length==0){
          this.$notify(
            {
              message:"请选择渠道",
              icon: 'nc-icon nc-app',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            })
          return;
        }
        if(this.gameName.length==0){
          this.$notify(
            {
              message:"请选择项目",
              icon: 'nc-icon nc-app',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            })
          return;
        }
        if(this.gameVersion.length==0){
          this.$notify(
            {
              message:"请选择版本",
              icon: 'nc-icon nc-app',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            })
          return;
        }
        let data = {cName:this.channelName,gName:this.gameName,gVersion:this.gameVersion};
        if(this.dateTimePicker1 != null && this.dateTimePicker2 != null){
          let dateTime1 = this.dateTimePicker1.getTime();
          let dateTime2 = this.dateTimePicker2.getTime();
          data.time1 = Math.floor(dateTime1 / 1000);
          data.time2 = Math.floor(dateTime2 / 1000);
          if(data.time1 == data.time2){
            this.$notify(
              {
                message:"起始时间不能等于终止时间！",
                icon: 'nc-icon nc-app',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'danger'
              })
            return;
          }
          if(data.time1 > data.time2){
            let temp = data.time1;
            data.time1 = data.time2;
            data.time2 = temp;
          }
        }else if(this.dateTimePicker1 != null || this.dateTimePicker2 != null){
          if(data.time1 == data.time2){
            this.$notify(
              {
                message:"时间漏填，本次查询不含时间限制",
                icon: 'nc-icon nc-app',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'warning'
              })
          }
        }
        if(this.isSearching){
          return;
        }
        this.isSearching = true;
        this.lastFindAllData = data;
        this.axios.post(this.$myStore.ServerUrl+'/statistics/getAll',data).then((response)=>{
          this.isSearching = false;
          if(response.data.code == 200){
            this.searchResult = response.data.data;
          }else{
            this.$notify(
              {
                message:"错误："+response.data.msg,
                icon: 'nc-icon nc-app',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'danger'
              })
          }
        }).catch((response)=>{
          console.log(response);
        })
      }
    },
  }

</script>
<style>

</style>
