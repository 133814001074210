var name = '';
var token = '';
const ServerUrl = "https://dyiaa.xxyxos.com";

function setCookie(name, value, time_sec) {
    if(time_sec == null){
      document.cookie = name + "=" + escape(value);
    }else{
      var exp = new Date();
      exp.setTime(exp.getTime() + time_sec * 1000);
      document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
    }
}
function getCookie(name) {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg)) return unescape(arr[2]);
    else return null;
}
function clearLogin(){
    this.token = '';
    this.setCookie("userToken","");
}

export default {
    name,
    token,
    ServerUrl,
    setCookie,
    getCookie,
    clearLogin
};
